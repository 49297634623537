import moment from 'moment';
import _ from 'lodash';
import { capacityExecitionList, monthlySubscriptions } from './packages';

export const getGuideInformation = (key = 'user') => {
  try {
    let info = JSON.parse(localStorage.getItem('CMZ:GUIDE') || {});

    return info[key] || false;
  } catch (error) {
    return false;
  }
};

export const setGuideInformation = (value) => {
  let info = {};
  try {
    info = JSON.parse(localStorage.getItem('CMZ:GUIDE') || {});
  } catch {
    info = {};
  }
  info[value] = true;
  localStorage.setItem('CMZ:GUIDE', JSON.stringify(info));
};

export const getStartInformation = (key = 'user') => {
  try {
    let info = JSON.parse(localStorage.getItem('CMZ:L') || {});

    return info[key] || {};
  } catch (error) {
    return {};
  }
};

export const getAccessToken = () => {
  try {
    return JSON.parse(localStorage.getItem('CMZ:T')).token;
  } catch (error) {
    return null;
  }
};

export const getRefreshToken = () => {
  return localStorage.getItem('CMZ:R');
};

export const getAccountStatusControl = () => {
  return getStartInformation('account').status === 1 ? true : false;
};

export const setIntegrationTargetBlank = (value) => {
  localStorage.setItem(
    'CMZ:Integration',
    JSON.stringify({ targetBlank: value }),
  );
};

export const getIntegrationTargetBlank = () => {
  return JSON.parse(localStorage.getItem('CMZ:Integration')).targetBlank;
};

export const isSuperUser = () => {
  return getStartInformation('user').is_super_user;
};

export const isItFirstTimeUser = () => {
  return getStartInformation('accounts').length === 0 ? true : false;
};

export const isItFirstAccount = () => {
  return getStartInformation('accounts').length === 1 ? true : false;
};

export const doesAccountHaveApplication = () => {
  return getStartInformation('account').statistics?.application === 0
    ? false
    : true;
};

export const getEmail = () => {
  return localStorage.getItem('CMZ:email');
};

export const doesHaveEmail = () => {
  return localStorage.getItem('CMZ:email') !== null;
};

export const doesHaveRefreshToken = () => {
  return localStorage.getItem('CMZ:R') !== null;
};

export const getAccountApiKey = () => {
  return getStartInformation('account').api_key;
};

export const getAccountUuid = () => {
  return getStartInformation('account').account_uuid;
};

export const getMenus = () => {
  try {
    let menus = getStartInformation('account').menu;

    return _.isEmpty(menus) ? [] : menus;
  } catch (error) {
    return [];
  }
};

export const isUserGroup = (user_group) => {
  try {
    let groups = getStartInformation().user_groups;

    return groups.indexOf(user_group) > -1;
  } catch (error) {
    return false;
  }
};

export const nullCheck = (value, fallback) => {
  try {
    if (fallback === undefined) fallback = '—';

    return value ? value : fallback;
  } catch (error) {
    return value;
  }
};

export const fullnameGenerator = (name, surname) => {
  return `${nullCheck(name, '')} ${nullCheck(surname, '')}`;
};

export const avatarPlaceholder = (name, surname) => {
  if (name) {
    return nullCheck(name, '').slice(0, 1) + nullCheck(surname, '').slice(0, 1);
  } else {
    return 'U';
  }
};

export const formatDate = (date, format = 'YYYY-MM-DD') => {
  return date && moment(date).isValid() ? moment(date).format(format) : '—';
};

export const getApplicationReference = () => {
  return localStorage.getItem('application_reference');
};
export const getAccount = () => {
  return JSON.parse(localStorage.getItem('CMZ:L')).account;
};
export const getAccountCurrentCapacity = () => {
  const subscriptionCapacity = JSON.parse(localStorage.getItem('CMZ:L')).account
    .subscription_capacity;
  if (capacityExecitionList.find((i) => i.value == subscriptionCapacity)) {
    return capacityExecitionList.find((i) => i.value == subscriptionCapacity);
  } else {
    return { id: 0, value: subscriptionCapacity };
  }
};

export const getAccountCapacity = () => {
  if (getStartInformation('account')) {
    return JSON.parse(localStorage.getItem('CMZ:L')).account
      .subscription_capacity;
  }
};

export const getHaveAccount = () => {
  const cmzData = localStorage.getItem('CMZ:L');

  // CMZ:L anahtarının değeri null veya undefined ise, veya account alt anahtarı yoksa false döndür
  if (!cmzData) {
    return false;
  }

  const parsedData = JSON.parse(cmzData);

  // Eğer account alt anahtarı varsa true, yoksa false döndür
  return parsedData.hasOwnProperty('account');
};

export const getAccountHaveCapacity = () => {
  const account = localStorage.getItem('CMZ:L') !== null;
  if (account) {
    const capacity = JSON.parse(localStorage.getItem('CMZ:L')).account
      .subscription_capacity;
    if (capacity !== 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getAccountCurrentPlan = () => {
  return monthlySubscriptions.find(
    (i) =>
      i.id ===
      JSON.parse(localStorage.getItem('CMZ:L')).account.subscription_id,
  );
};

export const getAccountCurrentPlanId = () => {
  return JSON.parse(localStorage.getItem('CMZ:L')).account.subscription_id;
};

export const getAccountUsedCapacity = () => {
  return JSON.parse(localStorage.getItem('CMZ:L')).account.execution_count;
};

export const isRegisteredUser = () => {
  return (
    localStorage.getItem('CMZ:R') !== null ||
    localStorage.getItem('CMZ:T') !== null
  );
};

export const inTrial = () => {
  return getStartInformation('trial')?.is_started;
};

export const isPaidCustommer = () => {
  const paidCustomer = getStartInformation('account').paid;
  if (paidCustomer === undefined) {
    return false;
  } else {
    return paidCustomer;
  }
};

export const mainPageUrl = () => {
  const account = getAccount();

  if (account.version == 2) {
    return "/select-tool";
  }

  return "/start-a-task";
}
